<div class="card">
  <div class="card-body">
    <h5>
      {{ "Lịch sử giao hàng của chi nhánh " | translate }}
      {{ data?.agency_name }}
    </h5>
    <div class="statistics-container d-flex justify-content-between">
      <div class="stat-card">
        <h4>Tổng số đã giao</h4>
        <p> {{dataManager?.total_device || 0}} </p>
      </div>
      <div class="stat-card">
        <h4>Tổng số đã bán</h4>
        <p> {{dataManager?.total_sold || 0}} </p>
      </div>
      <div class="stat-card">
        <h4>Tồn kho</h4>
        <p> {{dataManager?.total_stock || 0}} </p>
      </div>
      <div class="stat-card">
        <h4>Tốc độ phát triển (%)</h4>
      <p> {{dataManager?.growth_rate || 0}} %</p>
      </div>
      <!-- <div class="stat-card">
        <h4>Số đã bán/Tổng TB đã giao</h4>
        <p> {{dataManager?.data}} </p>
      </div> -->
    </div>

    <div class="d-flex justify-content-end d-flex">
      <button class="btn btn-success mb-2" (click)="openModal(content)">
        <i class="mdi mdi-file-excel mr-2"></i> {{ "Import Exel" | translate }}
      </button>
    </div>
    <div class="row mb-md-2"></div>
    <div class="table-responsive">
      <table
        id="basic-datatable"
        class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
      >
        <thead>
          <tr class="text-center bg-table">
            <th>{{ "#" | translate }}</th>
            <!-- <th>{{ "Tên chi nhánh" | translate }}</th> -->
            <th>{{ "Người gửi" | translate }}</th>
            <th>{{ "Người nhận" | translate }}</th>
            <th>{{ "Số lượng" | translate }}</th>
            <th>{{ "Tổng số loa" | translate }}</th>
            <th>{{ "Đã bán" | translate }}</th>
            <th>{{ "Tồn kho" | translate }}</th>
            <th>{{ "Biên bản" | translate }}</th>
            <th>{{ "Ngày giao hàng" | translate }}</th>
            <th>{{ "Loại hình" | translate }}</th>
            <th>{{ "Quản lý" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let data of listData; let i = index">
            <td>
              <a>{{ pageSize * (pageIndex - 1) + i + 1 }}</a>
            </td>
            <!-- <td>
              <a>{{ data.agency_name }}</a>
            </td> -->
            <td>
              <a>{{ data.deliverer_name }}</a>
            </td>
            <td>
              <a>{{ data.receiver_name }}</a>
            </td>
            <td>
              <a>{{ data.quantity }}</a>
            </td>
            <td>
              <a>{{ data.total_device }}</a>
            </td>
            <td>
              <a>{{ data.total_sold }}</a>
            </td>
            <td>
              <a>{{ data.total_inventory }}</a>
            </td>
            <td>
              <a class="hyperlink" *ngIf="data?.handoverRecordBase64" (click)="openPdfInNewTab(data?.handoverRecordBase64)">Xem PDF</a>
            </td>            
            <td>
              <a>{{ data.created_at | date : "dd/MM/yyyy" }}</a>
            </td>

            <td>
            <a>{{ data.is_return }}</a>
              <!-- <span
                class="p-1 rounded"
                [ngClass]="{
                  'badge-success text-white font-weight-bold':
                    data.is_return === 0,
                  'badge-danger text-white font-weight-bold':
                    data.is_return === 1
                }"
              >
                {{ data.is_return === 0 ? "Giao hàng" : "Trả hàng" }}
              </span> -->
            </td>
            <td class="py-2 table-column text-center">
              <div
                class="d-flex justify-content-md-center align-items-md-center"
              >
                <a
                  class="font-size-18 text-success"
                  (click)="openModalDetail(detail, data.id)"
                  ngbTooltip="{{ 'Chi tiết' | translate }}"
                >
                  <i class="mdi mdi-eye"></i>
                </a>
                <a class="font-size-18" href="javascript: void(0);" ngbTooltip="{{'FORM.Edit' | translate}}" placement="bottom-left" (click)="onEdit(data, content)">
                                      <i class="mdi mdi-pen"></i>
                                  </a>
                           <!--        <a class="font-size-18 text-danger" ngbTooltip="{{'FORM.Delete' | translate}}" placement="bottom-left" (click)="onDelete(data.id)">
                                      <i class="mdi mdi-delete"></i>
                                  </a> -->
                <!-- <a class="font-size-18 text-body" ngbTooltip="{{'FORM.KeywordManager' | translate}}" placement="left" (click)="onOpenKeywordModal(contentKeyword, data)">
                                      <i class="mdi mdi-cog"></i>
                                      {{'FORM.KeywordManager' | translate}}
                                  </a> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-md-between align-items-md-center mt-2">
      <div class="col-sm-12 col-md-5">
        <div
          class="dataTables_info mb-2"
          id="tickets-table_info"
          role="status"
          aria-live="polite"
        >
          {{ "TABLE.Showing" | translate }}
          {{ pageSize * pageIndex - pageSize + 1 }}
          {{ "TABLE.to" | translate }} {{ pageSize * pageIndex }}
          {{ "TABLE.of" | translate }} {{ totalCount }}
          {{ "TABLE.entries" | translate }}
        </div>
      </div>
      <!-- Pagination -->
      <div
        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end"
      >
        <div class="text-md-right float-md-right pagination-rounded">
          <ngb-pagination
            [collectionSize]="totalCount"
            [(page)]="pageIndex"
            [pageSize]="pageSize"
            [maxSize]="5"
            [ellipses]="true"
            (pageChange)="fetchData()"
          >
          </ngb-pagination>
        </div>
        <div class="dataTables_length ml-3" id="tickets-table_length">
          <label class="d-inline-flex align-items-center mb-0">
            {{ "TABLE.Show" | translate }}
            <select
              name="tickets-table_length"
              aria-controls="tickets-table"
              name="pageSize"
              [(ngModel)]="pageSize"
              (ngModelChange)="pageIndex = 1; fetchData()"
              class="custom-select custom-select-sm form-control form-control-sm mx-2"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
            {{ "TABLE.entries" | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content role="document" let-modal="close">
  <div class="modal-header d-flex justify-content-between align-items-center">
    <h5 class="modal-title">
      {{ (formData.value.id ? "Cập nhật biên bản bàn giao" : "Import Excel") | translate }}
    </h5>
    <button type="button" class="btn" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="formData">
        <div class="row mb-2">
          <div class="row m-0 p-0">
            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Số lượng thiết bị" | translate }}</label>
              <div class="col-12">
                <input type="text" class="form-control" formControlName="quantity" [disabled]="isUpdateMode" />
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Người gửi" | translate }}</label>
              <div class="col-12">
                <input type="text" class="form-control" formControlName="deliverer_name" [disabled]="isUpdateMode" />
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Người nhận" | translate }}</label>
              <div class="col-12">
                <input type="text" class="form-control" formControlName="receiver_name" [disabled]="isUpdateMode" />
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Người liên hệ" | translate }}</label>
              <div class="col-12">
                <input type="text" class="form-control" formControlName="contact_person" [disabled]="isUpdateMode" />
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Địa chỉ bàn giao" | translate }}</label>
              <div class="col-12">
                <input type="text" class="form-control" formControlName="shipping_address" [disabled]="isUpdateMode" />
              </div>
            </div>

            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Loại thiết bị" | translate }}</label>
              <ng-select formControlName="type_device" class="col-md-12" [disabled]="isUpdateMode">
                <ng-option [value]="" hidden>Chọn loại thiết bị</ng-option>
                <ng-option value="4G">Loa 4G</ng-option>
                <ng-option value="WIFI">Loa WIFI</ng-option>
              </ng-select>
            </div>

            <div class="form-group col-6">
              <label class="col-5 col-form-label required_label">{{ "Loại hình" | translate }}</label>
              <ng-select formControlName="is_return" class="col-md-12" [disabled]="isUpdateMode">
                <ng-option [value]="" hidden>Chọn loại hình</ng-option>
                <ng-option value="{{ item.key }}" *ngFor="let item of isReturn">
                  {{ item.value }}
                </ng-option>
              </ng-select>
            </div>
            <div class="form-group col-6" *ngIf="!isUpdateMode ; else updatePDF">
              <label class="col-5 col-form-label required_label">{{ "Chọn file Excel" | translate }}</label>
              <div class="col-12">
                <input type="file" id="fileExcel" accept=".xlsx, .xls" (change)="onFileChange($event)" class="form-control" />
              </div>
            </div>
            <ng-template #updatePDF>
              <div class="form-group col-6">
                <label class="col-5 col-form-label required_label">
                  Chọn file PDF
                </label>
                <div class="col-12">
                  <input type="file" id="fileExcel" accept=".pdf" (change)="onFilePdfChange($event)" class="form-control" />
                </div>
              </div>
              <div class="form-group col-6 ml-3">
                <mat-form-field>
                  <mat-label>Chọn ngày</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="dereceiptDate">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>              

            </ng-template>
            
          </div>
        </div>

        <div class="text-center">
          <button type="button" class="btn btn-secondary btn-lg mr-3" style="width: 150px" (click)="modal('close click')">
            {{ "Đóng" | translate }}
          </button>
          <button type="submit" class="btn btn-success btn-lg" style="width: 150px" 
            [disabled]="!formData.valid || !fileSelected">
            {{ "Xác nhận" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>


<ng-template #detail role="document" let-modal="close">
  <div class="modal-header d-flex justify-content-between align-items-center">
    <h5 class="modal-title">
      {{ "Chi tiết lịch sử giao dịch" | translate }}
    </h5>
    <button
      type="button"
      class="btn"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="table-responsive">
      <table
        id="basic-datatable"
        class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
      >
        <thead>
          <tr class="text-center bg-table">
            <th>{{ "#" | translate }}</th>
            <th>{{ "Model" | translate }}</th>
            <th>{{ "Serial Number" | translate }}</th>
            <th>{{ "Version" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            *ngFor="let data of listDataDetail; let i = index"
          >
            <td>
              <a>{{ pageSizeDetail * (pageIndexDetail - 1) + i + 1 }}</a>
            </td>
            <td>
              <a>{{ data.model }}</a>
            </td>
            <td>
              <a>{{ data.serial_number }}</a>
            </td>
            <td>
              <a>{{ data.version }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row justify-content-md-between align-items-md-center mt-2">
      <div class="col-sm-12 col-md-5">
        <div
          class="dataTables_info mb-2"
          id="tickets-table_info"
          role="status"
          aria-live="polite"
        >
          {{ "TABLE.Showing" | translate }}
          {{ pageSizeDetail * pageIndexDetail - pageSizeDetail + 1 }}
          {{ "TABLE.to" | translate }} {{ pageSizeDetail * pageIndexDetail }}
          {{ "TABLE.of" | translate }} {{ totalCountDetail }}
          {{ "TABLE.entries" | translate }}
        </div>
      </div>
      <!-- Pagination -->
      <div
        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-end"
      >
        <div class="text-md-right float-md-right pagination-rounded">
          <ngb-pagination
            [collectionSize]="totalCountDetail"
            [(page)]="pageIndexDetail"
            [pageSize]="pageSizeDetail"
            [maxSize]="5"
            [ellipses]="true"
            (pageChange)="getHistoryDetail()"
          >
          </ngb-pagination>
        </div>
        <div class="dataTables_length ml-3" id="tickets-table_length">
          <label class="d-inline-flex align-items-center mb-0">
            {{ "TABLE.Show" | translate }}
            <select
              name="tickets-table_length"
              aria-controls="tickets-table"
              name="pageSizeDetail"
              [(ngModel)]="pageSizeDetail"
              (ngModelChange)="pageIndexDetail = 1; getHistoryDetail()"
              class="custom-select custom-select-sm form-control form-control-sm mx-2"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
            </select>
            {{ "TABLE.entries" | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentLink role="document" let-modal="close">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ "Liên kết thiết bị và tài khoản" | translate }}
    </h5>
    <!-- <div *ngIf="makeForm('password').hasError('required')" class="error-message">
              Mật khẩu không được bỏ trống.
          </div> -->
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData2()" [formGroup]="formData2">
        <div class="row">
          <div class="form-group row col-md-12">
            <div class="col mt-3">
              <div class="d-flex">
                <label class="col-md-4 col-form-label required_label">{{
                  "Thiết bị" | translate
                }}</label>
                <div class="col-md-8">
                  <ng-select formControlName="device_id">
                    <ng-option [value]="-1">Chọn thiết bị</ng-option>
                    <ng-option
                      [value]="item.device_id"
                      *ngFor="let item of listData2"
                    >
                      {{ item.serial_number }}</ng-option
                    >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row col-md-12">
            <div class="col mt-3">
              <div class="d-flex">
                <label class="col-md-4 col-form-label required_label">{{
                  "Tài khoản" | translate
                }}</label>
                <div class="col-md-8">
                  <ng-select formControlName="bankaccount_id">
                    <ng-option [value]="-1">Chọn tài khoản</ng-option>
                    <ng-option
                      [value]="item.bankaccount_id"
                      *ngFor="let item of listData3"
                    >
                      {{ item.account_number }} - {{ item.bank_account_name }} -
                      {{ item.bank_name }}</ng-option
                    >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="text-right">
                      <button type="button" class="btn btn-secondary btn-sm mr-3"
                          (click)="modal('close click')">{{'Đóng' | translate}}</button>
                      <button type="submit" class="btn btn-primary btn-sm">{{'Lưu' | translate}}</button>
                  </div> -->
        <div class="text-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg mr-3"
            style="width: 150px"
            (click)="modal('close click')"
          >
            {{ "Đóng" | translate }}
          </button>
          <button
            type="submit"
            class="btn btn-success btn-lg"
            style="width: 150px"
          >
            {{ "Lưu" | translate }}
          </button>
          <!-- <button type="button" class="btn btn-secondary btn-sm" style="width: 150px"
                      (click)="modal('close click')">{{'FORM.Close' | translate}}</button>
                  <button type="button" class="btn btn-primary btn-sm ml-3" style="width: 150px"
                      (click)="updateFormNote()">{{'FORM.Save' | translate}}</button> -->
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #contentKeyword let-modalHis="close">
  <div class="modal-header">
    <h5 class="modal-title">List keyword of "{{ selectedIncident.name }}"</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modalHis('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="mb-3" *ngIf="formNewKeyword">
      <h5 class="font-size-14">Add New Keyword</h5>
      <form
        [formGroup]="formNewKeyword"
        (submit)="onSubmitFormKeyword(2)"
        class="d-flex"
      >
        <input
          type="text"
          class="form-control form-control-sm"
          style="width: 250px"
          formControlName="name"
        />
        <button class="btn btn-primary btn-sm ml-3" style="width: 150px">
          Add
        </button>
      </form>
    </div>
    <div class="table-responsive">
      <table
        id="basic-datatable"
        class="table table-bordered dt-responsive nowrap no-footer dtr-inline"
      >
        <thead>
          <tr>
            <th width="50">#</th>
            <th>Name</th>
            <!-- <th>Thời gian</th> -->
            <th width="170"></th>
          </tr>
        </thead>
        <tbody *ngFor="let data of dataKeyword; let i = index">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>
              <ng-container
                *ngIf="
                  selectedKeyword?.id == data.id && formEditKeyword;
                  else normal
                "
              >
                <form
                  [formGroup]="formEditKeyword"
                  (submit)="onSubmitFormKeyword(1)"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="name"
                  />
                </form>
              </ng-container>
              <ng-template #normal>{{ data.name }}</ng-template>
            </td>
            <!-- <td>{{data.created_at*1000 | date:'dd/MM/yyyy HH:mm'}}</td> -->
            <td>
              <div class="d-flex" style="gap: 10px">
                <a
                  class="btn btn-sm btn-success"
                  href="javascript: void(0);"
                  (click)="editKeyword(data, i)"
                >
                  <i class="mdi mdi-account-edit"></i
                  >{{ "FORM.Edit" | translate }}</a
                >
                <a
                  class="text-white btn btn-sm btn-danger"
                  (click)="onDeleteKeyword(data.id)"
                  ><i class="mdi mdi-delete"></i
                  >{{ "FORM.Delete" | translate }}</a
                >
              </div>
              <!-- <button class="btn" (click)="editKeyword(data,i)">Sửa</button>
                              <button>Xoá</button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

