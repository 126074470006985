// import { MatDateRangeModule } from '@angular/material/date-range';
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";

import { UiModule } from "../shared/ui/ui.module";
import { WidgetModule } from "../shared/widget/widget.module";

import { PagesRoutingModule } from "./pages-routing.module";

import {
  NgbNavModule,
  NgbDropdownModule,
  NgbTooltipModule,
  NgbPaginationModule,
  NgbDatepickerModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbCarouselModule,
} from "@ng-bootstrap/ng-bootstrap";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FullCalendarModule } from "@fullcalendar/angular";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

import { DashboardComponent } from "./dashboard/dashboard.component";

import { ServicesComponent } from "./services/services.component";
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from "../shared/services/common.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { ChartsModule } from "ng2-charts";
import { OrdersComponent } from "./listtransactions/orders.component";
import { LightboxModule } from "ngx-lightbox";
import { TranslateModule } from "@ngx-translate/core";
import { LocationComponent } from "./location/location.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { RolesComponent } from "./listdevices/roles.component";
import { UsersComponent } from "./users/users.component";
import { IncidentComponent } from "./incident/incident.component";
import { CustomersComponent } from "./customers/customers.component";
import { DetailsComponent } from "./detailsCutstomers/details.component";
import { DetailsTransactionsComponent } from "./details-transactions/details-transactions.component";
import { ListTransactionsComponent } from "./list-transactions/list-transactions.component";
import { ListQrpayCodeComponent } from "./list-qrpay-code/list-qrpay-code.component";
import { DetailQrpayCodeComponent } from "./detail-qrpay-code/detail-qrpay-code.component";
import { TokensManageComponent } from "./tokens-manage/tokens-manage.component";
import { CreateOrderComponent } from "./create-order/create-order.component";
import { QrCodeModule } from "ng-qrcode";
import { DetailCreateOrderComponent } from "./detail-create-order/detail-create-order.component";
import { ListBankAccountComponent } from "./list-bank-account/list-bank-account.component";
import { DetailBankaccountComponent } from "./detail-bankaccount/detail-bankaccount.component";
import { AccountComponent } from "./account/account.component";
import { ListMemberComponent } from "./list-member/list-member.component";
import { CurrencyMaskDirective } from "./currency-mask.directive";
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { ListWorkspaceComponent } from "./list-workspace/list-workspace.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { DetailWorkspaceComponent } from "./detail-workspace/detail-workspace.component";
import { SearchTransactionsComponent } from "./search-transactions/search-transactions.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { DeviceStockComponent } from "./device-stock/device-stock.component";
import { OrdersManagerComponent } from "./orders-manager/orders-manager.component";
import { DeviceQrComponent } from "./device-qr/device-qr.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from "@angular/material/core";
import { DashboardNewComponent } from "./dashboard-new/dashboard-new.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { DetalOrderComponent } from "./detal-order/detal-order.component";
import { GHNPendingComponent } from "./ghn-pending/ghn-pending.component";
import { ManagerQrComponent } from "./manager-qr/manager-qr.component";
import { BranchManagerComponent } from "./branch-manager/branch-manager.component";
import { BranchHistoryComponent } from "./branch-history/branch-history.component";
import { DetailHistoryComponent } from "./detail-history/detail-history.component";
import { MBDashboardComponent } from "./mb-dashboard/mb-dashboard.component";
import { RMManagementComponent } from "./rm-management/rm-management.component";
import { ReferralCodeComponent } from "./referral-code/referral-code.component";
import { DeviceComponent } from "./device/device.component";
import { TransactionReportComponent } from "./transaction-report/transaction-report.component";
import { ManagerGHNComponent } from "./manager-ghn/manager-ghn.component";
import { ReturnManagerComponent } from "./return-manager/return-manager.component";
import { DetailGhnPendingComponent } from "./detail-ghn-pending/detail-ghn-pending.component";
import { ReturnConfirmComponent } from "./return-confirm/return-confirm.component";
import { VtpPendingComponent } from "./vtp-pending/vtp-pending.component";
import { SearchOrderComponent } from "./search-order/search-order.component";
import { DetailSearchOrderComponent } from "./detail-search-order/detail-search-order.component";
import { ExpiredpageComponent } from "./expiredpage/expiredpage.component";
import { VccPendingComponent } from "./vcc-pending/vcc-pending.component";
import { VccDoneComponent } from "./vcc-done/vcc-done.component";
import { MatInputModule } from '@angular/material/input';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3,
};

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [
    DashboardComponent,
    ServicesComponent,
    OrdersComponent,
    LocationComponent,
    RolesComponent,
    UsersComponent,
    IncidentComponent,
    CustomersComponent,
    DetailsComponent,
    DetailsTransactionsComponent,
    ListTransactionsComponent,
    ListQrpayCodeComponent,
    DetailQrpayCodeComponent,
    TokensManageComponent,
    CreateOrderComponent,
    DetailCreateOrderComponent,
    ListBankAccountComponent,
    DetailBankaccountComponent,
    AccountComponent,
    ListMemberComponent,
    CurrencyMaskDirective,
    ListWorkspaceComponent,
    PagenotfoundComponent,
    DetailWorkspaceComponent,
    SearchTransactionsComponent,
    EditProfileComponent,
    DeviceStockComponent,
    OrdersManagerComponent,
    DeviceQrComponent,
    DashboardNewComponent,
    DetalOrderComponent,
    GHNPendingComponent,
    ManagerQrComponent,
    BranchManagerComponent,
    BranchHistoryComponent,
    DetailHistoryComponent,
    MBDashboardComponent,
    RMManagementComponent,
    ReferralCodeComponent,
    DeviceComponent,
    TransactionReportComponent,
    ManagerGHNComponent,
    ReturnManagerComponent,
    DetailGhnPendingComponent,
    ReturnConfirmComponent,
    VtpPendingComponent,
    SearchOrderComponent,
    DetailSearchOrderComponent,
    ExpiredpageComponent,
    VccPendingComponent,
    VccDoneComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    Ng2SearchPipeModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgxCurrencyModule,
    PerfectScrollbarModule,
    QrCodeModule,
    FullCalendarModule,
    WidgetModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgSelectModule,
    ChartsModule,
    LightboxModule,
    NgbCarouselModule,
    TranslateModule,
    GoogleMapsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgApexchartsModule,
    MatInputModule
  ],
  exports: [CurrencyMaskDirective],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class PagesModule {}
