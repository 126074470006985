import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { NgbDate, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { map } from "leaflet";
import { EventService } from "src/app/core/services/event.service";
import { ApiService } from "src/app/shared/services/api.service";
import Swal from "sweetalert2";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import { Lightbox, LightboxConfig } from "ngx-lightbox";
import { environment } from "src/environments/environment";
import { RouterLink } from "@angular/router";
import { SafeUrl } from "@angular/platform-browser";
import { CurrencyPipe,formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-branch-history",
  templateUrl: "./branch-history.component.html",
  styleUrls: ["./branch-history.component.scss"],
})
export class BranchHistoryComponent implements OnInit {
  constructor(
    private router: Router,
    private http: HttpClient,
    private apiService: ApiService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private eventService: EventService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}
  name: "";
  terminalAddress: "";
  terminalBankAcc: "";
  terminalBankName: "";
  terminalBankAccountName: "";
  listBankname = [
    {
      name: "MB Bank",
      id: "970422",
    },
  ];
  id = JSON.parse(localStorage.getItem("currentUser")).id;
  userToken = JSON.parse(localStorage.getItem("currentUser")).user_token;
  searchTerm;
  listData = [];
  listData2 = [];
  listData3 = [];
  listDataDetail;
  total;
  pageSize = 10;
  pageIndex = 1;
  pageSizeDetail = 10;
  pageIndexDetail = 1;
  totalCount = 0;
  totalCountDetail = 0;
  // tableName = 'workspaces'
  tableName1 = "agency/delivery-history";
  // tableName2 = 'devices/active'
  // tableName3 = 'bankaccounts/active'
  tableName4 = "agency/delivery-history";
  role = JSON.parse(localStorage.getItem("currentUser"))["role"];
  submitted: boolean;
  formData: FormGroup;
  formData2: FormGroup;
  listType = ["Trưởng nhóm", "Phó cửa hàng", "Thành viên"];
  hoveredDate: NgbDate;
  fromNGDate: NgbDate;
  toNGDate: NgbDate;
  breadCrumbItems;
  hidden: boolean = true;
  listDataUser;
  agencyId: any;
  isReturn = [
    {
      key: 0,
      value: "Giao hàng",
    },
    {
      key: 1,
      value: "Trả hàng",
    },
  ];
  fileSelected: boolean = false;
  get form() {
    return this.formData.controls;
  }
  fileExel: any;
  filePdf: any;
  detailId: null;
  data: any;
  fileName;
  isUpdateMode: boolean = false;
  allowFileChange: boolean = false;
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.agencyId = params.get("id");
    });
    this.breadCrumbItems = [
      { label: "PAGE.HOME" },
      { label: "PAGE.REPORT.IncidentType", active: true },
    ];
    this.fetchData();
    this.fetchDataManager();
  }
  openPdfInNewTab(base64: string) {
    if (!base64) return;

    try {
      const base64Data = base64.startsWith("data:application/pdf;base64,")
        ? base64.split(";base64,")[1]
        : base64;
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");
    } catch (error) {
      console.error("🚫 Lỗi xử lý Base64:", error);
    }
  }

  onFilePdfChange(event: any) {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      this.filePdf = file;
      console.log(this.filePdf)
      this.fileSelected = true;
    } else {
      alert("Vui lòng chọn tệp PDF hợp lệ.");
    }
  }

  onFileChange(event: any) {
    this.fileExel = event.target.files[0];
    if (this.fileExel) this.fileSelected = true;
  }

  onChangePage(e) {
    this.pageIndex = e;
    this.fetchData();
  }

  clearDate() {
    this.selected = "";
    this.filter.startdate = 0;
    this.filter.enddate = 0;
  }

  filter = {
    type_id: -1,
    name: "",
    // location_id: -1,
    status: -1,
    startdate: -1,
    enddate: -1,
  };
  searching = false;

  params = "";
  submitFilter() {
    this.params = "";
    Object.keys(this.filter).forEach((key) => {
      console.log(key + "-" + this.filter[key]);
      if (this.filter[key] || this.filter[key] >= 0) {
        this.params += `&${key}=${this.filter[key]}`;
      }
    });

    if (this.params) {
      this.pageIndex = 1;
      this.searching = true;
      this.fetchData();
    }
  }
  selected: any;

  clearFilter() {
    this.selected = "";
    this.params = "";
    this.filter.name = "";
    this.filter.type_id = -1;
    this.filter.status = -1;
    // this.filter.location_id = -1
    this.filter.startdate = 0;
    this.filter.enddate = 0;
    this.pageIndex = 1;
    // this.searching = false
    this.fetchData();
  }

  fetchData() {
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        this.tableName1 +
          `?agency_id=${this.agencyId}&page=${this.pageIndex}&limit=${this.pageSize}${this.params}`
      )
      .subscribe({
        next: (res) => {
          this.data = res["metadata"];
          this.listData = res["metadata"]["deliveryHistories"]
            ? res["metadata"]["deliveryHistories"]
            : [];
          this.totalCount = res.metadata.pagination.totalCount
            ? res.metadata.pagination.totalCount
            : "";
          this.eventService.setLoading(false);
        },
      });
  }
  dataManager;
  fetchDataManager() {
    this.eventService.setLoading(true);
    this.apiService
      //
      .getList(`dashboard/device-branch?agency_id=` + this.agencyId)
      .subscribe({
        next: (res) => {
          this.dataManager = res.metadata[0];
          this.eventService.setLoading(false);
        },
      });
  }
  getHistoryDetail() {
    this.eventService.setLoading(true);
    this.apiService
      .getList(
        `delivery-history/${this.detailId}?&page=${this.pageIndexDetail}&limit=${this.pageSizeDetail}`
      )
      .subscribe({
        next: (res) => {
          this.listDataDetail = res["metadata"]["listDevice"]["devices"]
            ? res["metadata"]["listDevice"]["devices"]
            : [];
          this.totalCountDetail = res.metadata.listDevice.pagination.totalCount
            ? res.metadata?.listDevice.pagination.totalCount
            : "";
          this.eventService.setLoading(false);
        },
      });
  }

  list_serial_number = [];

  // fetchData2(params = '') {
  //   params = '&order=updated_at&direction=desc'
  //   this.eventService.setLoading(true)
  //   this.apiService.getList(this.tableName2 +
  //     `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
  //   ).subscribe({
  //     next: (res) => {
  //       // this.listData = res.workspaces
  //       this.listData2 = res['devices'] ? res['devices'] : [];

  //       this.listData.forEach((item) => {
  //         if (item['id']) {
  //           this.list_serial_number.push(item['id']);
  //         }
  //       })
  //       console.log(this.list_serial_number)
  //       this.totalCount = res.length
  //       this.eventService.setLoading(false)
  //     }
  //   })
  // }

  list_id_account = [];

  // fetchData3(params = '') {
  //   params = '&order=updated_at&direction=desc'
  //   this.eventService.setLoading(true)
  //   this.apiService.getList(this.tableName3 + `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
  //   ).subscribe({
  //     next: (res) => {
  //       this.listData3 = res['bankaccounts'] ? res['bankaccounts'] : [];
  //       console.log(this.listData3)

  //       this.listData3.forEach((item) => {
  //         if (item['id']) {
  //           this.list_id_account.push(item['id']);
  //         }

  //       }
  //       )

  //       // console.log(this.list_id_account)
  //       this.totalCount = res.length
  //       // console.log(this.totalCount)
  //       // // this.totalCount = res.meta.total
  //       this.eventService.setLoading(false)
  //     }
  //   })
  // }
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = "";
      this.filter.startdate = 0;
      this.filter.enddate = 0;
    } else if (
      this.fromDate &&
      !this.toDate &&
      (date.after(this.fromNGDate) || date.equals(this.fromNGDate))
    ) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;
      // this.selected = this.fromDate.toLocaleDateString() + '-' + this.toDate.toLocaleDateString();
      this.selected =
        moment(this.fromDate).format("DD/MM/YYYY") +
        "-" +
        moment(this.toDate).format("DD/MM/YYYY");
      this.filter.startdate = moment
        .utc(this.fromDate.toLocaleString())
        .startOf("days")
        .unix();
      this.filter.enddate = moment
        .utc(this.toDate.toLocaleString())
        .endOf("days")
        .unix();
      // this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });

      this.fromDate = null;
      this.toDate = null;
      this.fromNGDate = null;
      this.toNGDate = null;
    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = "";
      this.filter.startdate = 0;
      this.filter.enddate = 0;
    }
  }

  addLang() {
    const language = this.form.language as FormArray;
    let lang = this.makePrice();
    language.push(lang);
  }

  removeLang(i) {
    const language = this.form.language as FormArray;
    language.removeAt(i);
  }

  openModal(content: any) {
    // this.apiService.successmsg()
    this.isUpdateMode = false;
    this.submitted = false;
    this.formData = this.makeForm({ language: [{}] });
    let a = this.modalService.open(content, {
      centered: true,
      scrollable: true,
      size: "lg",
    });
  }
  onEdit(data, content) {
    this.isUpdateMode = true;
    this.formData = this.makeForm(data);
    this.formData.controls["quantity"].disable();
    this.formData.controls["deliverer_name"].disable();
    this.formData.controls["receiver_name"].disable();
    this.formData.controls["contact_person"].disable();
    this.formData.controls["shipping_address"].disable();
    this.formData.controls["type_device"].disable();
    this.formData.controls["is_return"].disable();
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      size: "xl",
    });
  }
  openModalDetail(detail: any, id: any) {
    this.detailId = id;
    this.getHistoryDetail();
    this.submitted = false;
    let a = this.modalService.open(detail, {
      centered: true,
      scrollable: true,
      size: "xl",
    });
  }

  openModalLink(contentLink) {
    this.submitted = false;
    this.formData2 = this.makeForm2({ language: [{}] });
    let a = this.modalService.open(contentLink, {
      centered: true,
      scrollable: true,
      size: "lg",
    });
  }

  selectedIncident = null;
  selectedKeyword = null;

  makeFormKeyword(d) {
    let data = d ? d : <any>{};
    return this.formBuilder.group({
      id: [data.id],
      name: [data.name],
      incident_type_id: [data.incident_type_id],
    });
  }

  fetchKeyword() {
    this.dataKeyword = [];
    this.eventService.setLoading(true);
    this.apiService
      .getList(`keyword?type_id=${this.selectedIncident.id}`)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.dataKeyword = [...res];
          this.eventService.setLoading(false);
        },
      });
  }

  dataKeyword = [];

  formEditKeyword: FormGroup;
  formNewKeyword: FormGroup;
  editKeyword(data, index) {
    this.formEditKeyword = this.makeFormKeyword(data);
    this.selectedKeyword = data;
  }

  onSubmitFormKeyword(type) {
    if (type == 1) {
      if (this.formEditKeyword.valid) {
        const value = this.formEditKeyword.value;
        this.eventService.setLoading(true);
        this.apiService.editItem("keyword", value.id, value).subscribe({
          next: (res) => {
            this.selectedKeyword = null;
            this.fetchKeyword();
            this.eventService.setLoading(false);
          },
        });
      }
    } else {
      if (this.formNewKeyword.valid) {
        const value = this.formNewKeyword.value;
        this.eventService.setLoading(true);
        this.apiService.addItem("keyword", value).subscribe({
          next: (res) => {
            this.eventService.setLoading(false);
            this.formNewKeyword.reset({
              incident_type_id: this.selectedIncident.id,
            });
            this.fetchKeyword();
          },
        });
      }
    }
  }

  onOpenKeywordModal(content, incident) {
    this.selectedIncident = incident;
    this.fetchKeyword();
    this.formNewKeyword = this.makeFormKeyword({
      incident_type_id: this.selectedIncident.id,
    });
    this.modalService.open(content, {
      centered: true,
      scrollable: true,
      size: "xl",
    });
  }

  defaultPrices = [
    { condition: 1, price: 0, unit: "" },
    { condition: 2, price: 0, unit: "" },
  ];

  onDeleteKeyword(id) {
    if (id) {
      this.eventService.setLoading(true);
      this.apiService.deleteItem("keyword", id).subscribe((res) => {
        Swal.fire(
          this.translateService.instant("FORM.Success"),
          this.translateService.instant("FORM.SuccessMessageDelete"),
          "success"
        ).then((res) => {
          this.fetchKeyword();
        });
        this.eventService.setLoading(false);
      });
    }
  }

  onDelete(data) {
    Swal.fire({
      title: this.translateService.instant("FORM.Warning"),
      text: this.translateService.instant("FORM.WarningMessage"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      cancelButtonColor: "#ff3d60",
      confirmButtonText: this.translateService.instant("FORM.WarningConfirm"),
      cancelButtonText: this.translateService.instant("FORM.Cancel"),
    }).then((result) => {
      if (result.value) {
        this.apiService.deleteItem(this.tableName1, data).subscribe((res) =>
          Swal.fire(
            this.translateService.instant("FORM.Success"),
            this.translateService.instant("FORM.SuccessMessageDelete"),
            "success"
          ).then((res) => {
            this.fetchData();
          })
        );
      }
    });
  }

  makeForm(d?) {
    let data = d ? d : <any>{};
    return this.formBuilder.group({
      agency_id: [data.agency_id],
      id: [data.id],
      dereceiptDate: [data.dereceiptDate || null],
      quantity: [data.quantity, [Validators.required]],
      receiver_name: [data.receiver_name, [Validators.required]],
      deliverer_name: [data.deliverer_name, [Validators.required]],
      is_return: [data.is_return, [Validators.required]],
      contact_person: [data.contact_person, [Validators.required]],
      shipping_address: [data.shipping_address, [Validators.required]],
      type_device: [data.type_device, [Validators.required]],
    });
  }

  makeForm2(d?) {
    let data = d ? d : <any>{};
    return this.formBuilder.group({
      // id: [data.id],
      // imei : [data.imei, [Validators.required]],
      // description: [data.description],
      // price: [data.price || 0, [Validators.required]],
      // model : [data.model, [Validators.required]],
      // image_url: [data.image_url, [Validators.required]],
      device_id: [data.device_id, [Validators.required]],
      bankaccount_id: [data.bankaccount_id, [Validators.required]],
      // type: [data.type, [Validators.required]],
      // currency: [data.currency || 'VND'],
    });
  }

  makePrices(data = []) {
    let arr = new FormArray([]);
    data.forEach((i) => {
      arr.push(this.makePrice(i));
    });
    return arr;
  }

  viewDetails(data: any) {
    this.router.navigate(["/workspaces/detail-workspace", data.id]);
  }

  makePrice(d?) {
    let data = d ? d : <any>{};
    return this.formBuilder.group({
      key: [data.key, [Validators.required]],
      value: [data.value, [Validators.required]],
      // id: [data.id]
    });
  }

  uploadedImage;

  saveData() {
    this.submitted = true;
    if (this.isUpdateMode) {
      const formValue = this.formData.value;
      const formDataToSend = new FormData();
      formDataToSend.append("file", this.filePdf);
      formDataToSend.append("dereceiptDate",  formatDate(this.formData.value.dereceiptDate, 'yyyy-MM-dd', 'en')); 
      this.apiService
        .changeStatus("delivery-history/hardover-record" , formValue.id, formDataToSend)
        .subscribe({
          next: (res) => {
            this.submitted = false;
            this.formData.reset();
            this.fetchData();
            this.modalService.dismissAll();
            this.apiService.showToast(
              this.translateService.instant("FORM.Success"),
              this.translateService.instant("FORM.SuccessMessage"),
              "success"
            );
            this.eventService.setLoading(false);
          },
          error: (e) => {
            if (e) {
              this.apiService.showToast(
                this.translateService.instant("FORM.Error"),
                this.translateService.instant(e),
                "error"
              );
            }
            this.apiService.showToast(
              this.translateService.instant("FORM.Error"),
              this.translateService.instant("FORM.ErrorMessage"),
              "error"
            );
            this.eventService.setLoading(false);
          },
        });
      return;
    }
    if (this.formData.valid) {
      const formValue = this.formData.value;
      const formDataToSend = new FormData();
      formDataToSend.append("file", this.fileExel);
      formDataToSend.append("agency_id", this.agencyId);
      formDataToSend.append("quantity", formValue.quantity);
      formDataToSend.append("deliverer_name", formValue.deliverer_name);
      formDataToSend.append("receiver_name", formValue.receiver_name);
      formDataToSend.append("is_return", formValue.is_return);
      formDataToSend.append("shipping_address", formValue.shipping_address);
      formDataToSend.append("type_device", formValue.type_device);
      formDataToSend.append("contact_person", formValue.contact_person);
      this.eventService.setLoading(true);
      this.apiService
        .addItem("delivery-devices/import-file", formDataToSend)
        .subscribe({
          next: (res) => {
            this.submitted = false;
            this.formData.reset();
            this.fetchData();
            this.modalService.dismissAll();
            this.apiService.showToast(
              this.translateService.instant("FORM.Success"),
              this.translateService.instant("FORM.SuccessMessage"),
              "success"
            );
            this.eventService.setLoading(false);
          },
          error: (e) => {
            if (e) {
              this.apiService.showToast(
                this.translateService.instant("FORM.Error"),
                this.translateService.instant(e),
                "error"
              );
            }
            this.apiService.showToast(
              this.translateService.instant("FORM.Error"),
              this.translateService.instant("FORM.ErrorMessage"),
              "error"
            );
            this.eventService.setLoading(false);
          },
        });
    } else {
      console.log("invalid", this.formData);
      Object.values(this.formData.controls).forEach((control) => {
        if (control.status == "INVALID") {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  saveData2() {
    this.submitted = true;
    if (this.formData2.valid) {
      const value = this.formData2.value;
      // value['status'] = value['status'] == true ? 0 : 1
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true);
      if (value.id) {
        this.apiService.editItem(this.tableName4, value.id, value).subscribe({
          next: (res) => {
            this.submitted = false;
            this.formData2.reset();
            // this.fetchData2()
            // this.fetchData3()
            this.modalService.dismissAll();
            this.apiService.showToast(
              this.translateService.instant("FORM.Success"),
              this.translateService.instant("FORM.SuccessMessage"),
              "success"
            );
            this.eventService.setLoading(false);
          },
          error: (e) => {
            this.apiService.showToast(
              this.translateService.instant("FORM.Error"),
              this.translateService.instant("FORM.ErrorMessage"),
              "error"
            );
            this.eventService.setLoading(false);
          },
        });
      } else {
        this.apiService.addItem("this.tableName4", value).subscribe({
          next: (res) => {
            // this.fetchData2()
            // this.fetchData3()
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "success",
              title: this.translateService.instant("FORM.Success"),
            });

            this.eventService.setLoading(false);
            this.modalService.dismissAll();
          },
          error: (e) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: "error",
              title: this.translateService.instant("FORM.ErrorMessageAdd"),
            });
            this.eventService.setLoading(false);
            // this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.userNotExist'), 'error')
            // this.eventService.setLoading(false)
          },
        });
      }
    } else {
      console.log("invalid", this.formData2);
      Object.values(this.formData2.controls).forEach((control) => {
        if (control.status == "INVALID") {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  isInside(date: NgbDate) {
    return date.after(this.fromNGDate) && date.before(this.toNGDate);
  }
  isHovered(date: NgbDate) {
    return (
      this.fromNGDate &&
      !this.toNGDate &&
      this.hoveredDate &&
      date.after(this.fromNGDate) &&
      date.before(this.hoveredDate)
    );
  }
  /**
   * @param date date obj
   */
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromNGDate) ||
      date.equals(this.toNGDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
}
